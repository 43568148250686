const { status } = require('./const');
const ScriptDefinition = require('./ScriptDefinition');

/**
 * @property {boolean} navigating set to true when page is redirecting/navigating
 */
class ScriptLoader {
    navigating = false;

    /**
     * @param {ScriptDefinition[]} scripts
     * @param {Function} onload
     */
    constructor(scripts = [], onload = undefined) {
        this.scripts = ScriptLoader.filterScripts(scripts);
        this.setOnload(onload);
    }

    /**
     * @param {Function} onload
     */
    setOnload(onload = undefined) {
        if (typeof onload === 'function') {
            this.onload = onload;
        }
    }

    /**
     * Removes any non-ScriptDefinition instances from the array and returns it
     *
     * @param {any[]} scripts
     * @returns {ScriptDefinition[]}
     */
    static filterScripts(scripts) {
        return scripts.filter((script) => {
            if (script instanceof ScriptDefinition === false) {
                console.error("Script isn't an instance of ScriptDefinition:", script);
                return false;
            }
            return true;
        });
    }

    /**
     * @returns {DocumentFragment}
     */
    render() {
        const scriptsFragment = document.createDocumentFragment();
        this.scripts.forEach((script) => {
            const scriptElement = script.render();
            scriptsFragment.append(scriptElement);
        });
        return scriptsFragment;
    }

    /**
     * @returns {Document}
     */
    static getDocument() {
        return document;
    }

    /**
     * @returns {Promise<void>}
     */
    static pageLoaded() {
        return new Promise((resolve) => {
            if (ScriptLoader.getDocument().readyState === 'complete') {
                // when page is already loaded
                resolve();
            } else {
                window.addEventListener('load', () => {
                    resolve();
                });
            }
        });
    }

    async startLoading() {
        const scriptsFragment = this.render();
        await ScriptLoader.pageLoaded();
        document.head.append(scriptsFragment);

        addEventListener('beforeunload', () => {
            this.navigating = true;
        });
    }

    /**
     * @returns {Promise<string>}
     */
    async getReport() {
        const serializedScripts = await Promise.all(this.scripts.map((script) => script.serialize()));
        const erroredScriptsURLs = serializedScripts.filter((script) => script.status === status.errored).map((script) => script.url);
        return JSON.stringify({ erroredScriptsURLs, allScripts: serializedScripts }, null, 4);
    }

    /**
     * @returns {Promise<boolean>}
     */
    async allLoadedSuccessfully() {
        try {
            await Promise.all(this.scripts.map((script) => script.status));
            return true;
        } catch (e) {
            return false;
        }
    }

    static hideLoader() {
        // document.getElementById('script-loader-loading').style.display = 'none';
    }

    static showError() {
        document.getElementById('auth-cover-wrapper').style.display = 'none';
        document.getElementById('script-loader-error').style.display = 'block';
    }

    async checkStatus() {
        const allLoadedSuccessfully = await this.allLoadedSuccessfully();

        if (allLoadedSuccessfully) {
            ScriptLoader.hideLoader();
            this.onload && this.onload();
        } else if (this.navigating) {
            console.log('Script loading failed due to redirect/navigation, ignoring...');
        } else {
            window.BW_ScriptLoader_Errored = true;
            ScriptLoader.showError();
            console.error('ScriptLoader -> checkStatus failed', await this.getReport());
        }
    }
}

module.exports = ScriptLoader;
