const { status } = require('./const');

class ScriptDefinition {
    /**
     *
     * @param {string} name - friendly script name
     * @param {string} url - script's src
     * @param {object} [attributes] - additional attributes, for example {"type": "module", "crossorigin": true}; no need for `type="text/javascript"`, see https://stackoverflow.com/a/5265361/4536543
     */
    constructor(name, url, attributes) {
        this.name = name;
        this.url = url;
        this.attributes = { async: false, ...attributes }; // async=false to respect order, see https://webplatform.github.io/docs/html/attributes/async/#Programmatically-Created-Scripts
    }

    onLoad() {
        this.resolveLoading(status.loaded);
    }

    onError() {
        this.rejectLoading(status.errored);
    }

    status = new Promise((resolve, reject) => {
        this.resolveLoading = resolve;
        this.rejectLoading = reject;
    });

    /**
     * @returns {Promise<{name: string, url: string, attributes?: object, status: import("./const").ScriptLoadingStatus}>}
     */
    async serialize() {
        /** @type {import("./const").ScriptLoadingStatus} */
        let scriptStatus;
        try {
            scriptStatus = await Promise.race([this.status, Promise.resolve(status.unfinished)]); // if status promise is not already resolved/rejected - "unfinished" will be returned
        } catch (rejectionStatus) {
            scriptStatus = rejectionStatus;
        }
        return {
            name: this.name,
            url: this.url,
            attributes: this.attributes,
            status: scriptStatus,
        };
    }

    /**
     * @returns {HTMLScriptElement}
     */
    render() {
        const element = document.createElement('script');

        element.onload = () => this.onLoad();
        element.onerror = () => this.onError();

        element.src = this.url;

        Object.keys(this.attributes).forEach((attributeName) => {
            element[attributeName] = this.attributes[attributeName];
        });

        return element;
    }
}

module.exports = ScriptDefinition;
